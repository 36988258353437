import { Alert } from "reactstrap";
import React, { Component } from 'react';

export class InfoArea extends Component {
    static displayName = "Information";

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            infoAreaContents: Object,
            loadingInfoAreaContents: true
        };
    }

    async populateInfoAreaContents() {
        const response = await fetch('api/home/getinfoareacontents?cityCode=' + this.props.cityCode);
        const data = await response.json();
        if (data.success === true) {
            this.setState({ infoAreaContents: data.message, loadingInfoAreaContents: false });
        }
    }

    componentDidMount() {
        this.populateInfoAreaContents();
    }

    render() {

        if (this.state.loadingInfoAreaContents) {
            return <div></div>
        }

        return (
            <Alert color="info">
                {this.state.infoAreaContents}
            </Alert>
        );
    }
}
export default InfoArea;