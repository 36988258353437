import React, {Component} from "react";
import { Autoplay, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Link} from "react-router-dom";

export class MovieCarousel extends Component {
    static displayName = MovieCarousel.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            carouselMovies: [],
            cityCode: this.props.cityCode,
            loadingMovieCarouselList: true
        };
    }

    async componentDidMount() {        
        await this.populateMovieCarouselList();
    }

    renderMovieCarouselList(items) {
        let processedItems = items.map(movie => {
            return <SwiperSlide key={movie.filmID} value={movie.filmID} className="movieCarouselDiv" title={movie.title} alt={movie.title}>
                <Link to={{ pathname: '/Filmer/' + encodeURIComponent(movie.title) + "/" + movie.filmID, query: { filmId: movie.filmID } }}>
                    <img key={movie.filmID} value={movie.airTime}
                        alt=''
                        src={movie.poster}
                        className="movieCarouselImage" />
                </Link>
                {/*<div className="spanMovieSliderInfo">Första visn.: {movie.airTime}</div>*/}
            </SwiperSlide>
        }
        );

        return (<Swiper modules={[Autoplay, FreeMode]}
            spaceBetween={0}
            slidesPerView={6}
            loop={true}
            autoplay={true}
            freeMode={{ enabled: true }}
            delay={1}
            spaceBetween={20}
            speed={3000}>
            {processedItems}
        </Swiper>)
    }

    async populateMovieCarouselList() {
        if (this.state.loadingMovieCarouselList === true) {
            const response = await fetch('api/home/getmoviecarousellist?cityCode=' + this.props.cityCode);
            const data = await response.json();
            console.log(data);
            this.setState({ carouselMovies: data, loadingMovieCarouselList: false });
        }
    }

    render()
    {
        const slideValues = this.state.carouselMovies.length==0 ? "Laddar filmer" : (this.renderMovieCarouselList(this.state.carouselMovies));
        return (<div>{slideValues}</div> )
    }
}