import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import {Link} from "react-router-dom";
import BokaSalongLayout from '.././Boka/BokaSalongLayout';
import MovieInfo from ".././General/MovieInfo";
import MoviePoster from ".././General/MoviePoster";
import {Button} from "reactstrap";
import GeneralModal from "../General/Modal";
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";
const cookies = new Cookies();

export class Boka extends Component {
    //static displayName = Boka.name;   

    constructor(props) {
        super(props);
        const cookieValue = cookies.get('eurostarcookievalues');
        
        this.populateBookMovieTitle = this.populateBookMovieTitle.bind(this);
        this.populateCurrentCinemaName = this.populateCurrentCinemaName.bind(this);
        this.launchTrailer = this.launchTrailer.bind(this);
        this.handler = this.handler.bind(this);
        
        this.state = {
            cityCode: cookieValue === null || cookieValue === undefined ? 11 : cookieValue.cityCode,
            cityName: cookieValue === null || cookieValue === undefined ? "" : cookieValue.cinemaName,
            phoneNo: cookieValue === null || cookieValue === undefined ? "" : cookieValue.userPhone,
            loadingBookMovieList: true,
            loadingBookMovieItem: true,
            bookMovieList: [],
            bookMovieItem: [],
            seatsBooked: 0,
            isModalOpen: false,
            modalContents: "",
            modalType: "",
            modalHeader: "Trailer",
            cinemaName: ""
        };
    }

    componentDidMount() {
       document.title = "Eurostar Biografer - Boka biljett";
       if (this.props.match.params.showingId) {
            this.populateBookMovieView();
        }
        else {
           this.populateBookMovieList();
           this.populateCurrentCinemaName();
        }
    }

    populateBookMovieTitle() {
        let movieSuffix = "";
        let movieNewLineSuffix = "";
        //console.log(this.state.bookMovieItem);
        if (this.state.bookMovieItem.bookMovie.filmGroupId !== "" && this.state.bookMovieItem.bookMovie.filmGroupId != null) {
            if (this.state.bookMovieItem.bookMovie.versionShort !== ""
                && this.state.bookMovieItem.bookMovie.versionShort !== undefined
                && this.state.bookMovieItem.bookMovie.versionShort !== null) {
                if (this.state.bookMovieItem.bookMovie.versionShort.length > 2) {
                    movieNewLineSuffix = <h5>{this.state.bookMovieItem.bookMovie.version}</h5>
                }
                else
                {
                    movieSuffix = " " + this.state.bookMovieItem.bookMovie.version;
                }
            }
        }
        document.title = "Eurostar Biografer - Boka biljett till " + this.state.bookMovieItem.bookMovie.title + movieSuffix;
        return (<div><h2>Boka platser - {this.state.bookMovieItem.bookMovie.title + movieSuffix}</h2>{movieNewLineSuffix}</div>)
    }
    
    async populateBookMovieView() {
        const response = await fetch('api/home/GetBookMovie?showingId=' + this.props.match.params.showingId + "&userPhone=" + this.state.phoneNo);
        const data = await response.json();

        let cookieValues = {
            cityCode: data.komplexNr,
            cinemaPhone: data.bookTheatre.phoneNo,
            cinemaName: data.bookTheatre.city,
            userPhone: this.state.phoneNo
        }

        cookies.set('eurostarcookievalues', cookieValues, { path: '/', maxAge: 1000000 });
        if (this.state.cityCode !== data.komplexNr) {
            window.location.reload();
        }

        this.setState({ cityCode: data.komplexNr, bookMovieItem: data, loadingBookMovieItem: false, videoLink: "https://www.youtube.com/embed/" + data.bookMovie.trailer + "?&autoplay=1&modestbranding=1&rel=0&iv_load_policy=3" });        
    }

    async populateCurrentCinemaName() {
        const response = await fetch('api/home/GetCinemaName?cityCode=' + this.state.cityCode);
        const data = await response.json();
        this.setState({ cinemaName: data.name, cityName: data.city });
    }

    async populateBookMovieList() {
        
        const response = await fetch('api/home/GetBookMovieList?cityCode=' + this.state.cityCode);
        const data = await response.json();

        let processedItems = data.map(movie =>
            <div className="row rowBoka table-striped" key={ movie.filmID } value={movie.filmID}>
                <div className="col-md-1 divBokaImageCell"><img key={movie.filmID} value={movie.airTime}
                                               alt=''
                                               src={movie.poster}
                                               className="divMovieImage" /></div>
                <div className="col-md-5"><Link to={{ pathname: '/Filmer/'+encodeURIComponent(movie.title)+"/"+movie.filmID} }>{movie.title}<br/></Link><p className="pBookMovieDescription">{movie.description}</p></div>
                <div className="col-md-6">{movie.showings.map(showing => <Link key={showing.showFileName} to={{ pathname: '/Boka/' + showing.showFileName }}><div className="divBokaAirTime">{showing.airTime}{showing.version ? " (" + showing.version + ")": ""}</div></Link>)}</div>
            </div>
        );
        this.setState({ bookMovieList: processedItems, loadingBookMovieList: false });

    }
    
    launchTrailer() {
        this.setState({isModalOpen: true, modalHeader: "Trailer", modalContents: "", modalType: 2});
    }

    handler() {
        this.setState({
            isModalOpen: false
        })
    }

    render() {

        const currentBaseUrl = window.location.href;

        if (this.props.match.params.showingId)
        {
            if (this.state.loadingBookMovieItem) {
                return (<div className="white">Laddar visning...</div>)
            }
            
            return (
                <div>
                    <div className="page-header">
                        <div className="row">
                            <div className="col-md-12">
                                {this.populateBookMovieTitle()}

                            </div>
                            <div className="col-md-2">
                                <MoviePoster movieItem={this.state.bookMovieItem.bookMovie} />
                                
                                <Button className="btn-trailer" onClick={()=>this.launchTrailer()} >Se Trailer</Button>
                                <GeneralModal specialClass="trailerClass" isModalOpen={this.state.isModalOpen} modalHeader={this.state.modalHeader} modalType={this.state.modalType} modalContents={this.state.modalContents} videoLink={this.state.videoLink} handler={this.handler} />
                            </div>
                            <div className="col-md-5">
                                <MovieInfo cinemaName={this.state.bookMovieItem.bookTheatre.name} city={this.state.bookMovieItem.bookTheatre.city} salonName={this.state.bookMovieItem.bookSalon.name} cinemaInfo="1" movieItem={this.state.bookMovieItem.bookMovie} />                                
                            </div>
                            <div className="col-md-5">
                                <BokaSalongLayout movieName={this.state.bookMovieItem.bookMovie.title} cinemaName={this.state.bookMovieItem.bookTheatre.name} phoneNo={this.state.phoneNo} showFileName={this.props.match.params.showingId} seatsBooked={this.state.seatsBooked} time={this.state.time} city={this.state.cityCode} bookMovieItem={this.state.bookMovieItem} />
                            </div>
                        </div>
                    </div>
                </div>                
            );
        } else {

                if (this.state.loadingBookMovieList) {
                    return (<div className="white">Laddar filmer...</div>)
                }

                return (
                    <div>
                        <div className="page-header">
                            <h2>Boka</h2>
                            <p>Nedan finns en lista över aktuella filmer som går att boka på {this.state.cinemaName} i {this.state.cityName}.</p>
                            <div className="row divBokaHeader">
                                <div className="col-md-6">Film</div>
                                <div className="col-md-6">Visningar</div>
                            </div>
                            <div className="tableBoka">{this.state.bookMovieList}</div>
                            <br/>
                            <FacebookShareButton quote={"Kolla här!"} hashtag={"EurostarBiografer"} url={currentBaseUrl}><FacebookIcon size={32} round={false} /></FacebookShareButton> &nbsp;
                            <TwitterShareButton title={"Kolla här!"} hashtags={["EurostarBiografer"]} url={currentBaseUrl}><TwitterIcon size={32} round={false} /></TwitterShareButton>
                        </div>
                    </div>
                );
            
        }
        
    }
}
