import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import ReactGA from 'react-ga';

export class Layout extends Component {
  static displayName = Layout.name;
    
  render () {
    const trackingId = "UA-54347556-1";
    ReactGA.initialize(trackingId);      
    return (
      <div>
        <NavMenu cityCode={this.props.cityCode} contactPhone={this.props.contactPhone} cinemaName={this.props.cinemaName} />
        <Container className="contentContainer">
          {this.props.children}
        </Container>
        <Footer facebookPage={this.props.facebookPage} cityCode={this.props.cityCode} contactPhone={this.props.contactPhone} version={this.props.version} />
      </div>
    );
  }
}
