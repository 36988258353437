import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import Cookies from 'universal-cookie';

class GeneralModal extends React.Component {
    constructor(props) {
        super(props);

        this.renderCityDropDown = this.renderCityDropDown.bind(this);
        this.populateCityList = this.populateCityList.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateActiveCity = this.updateActiveCity.bind(this);

        this.state = {
            cities: [],
            showModal: false,
            loadingCities: true,
            dropdownOpen: false
        }
    }

    componentDidMount() {
        if (this.props.modalType === "4") {
            this.populateCityList();
        }
    }

    updateActiveCity(cityEntry) {
        if (cityEntry) {
            const cookies = new Cookies();
            const cookieValue = cookies.get('eurostarcookievalues');
            let cookieValues = {
                cityCode: cityEntry.code,
                cinemaPhone: cityEntry.phone,
                cinemaName: cityEntry.name,
                userPhone: cookieValue ? cookieValue.userPhone : ""
            }
            cookies.set('eurostarcookievalues', cookieValues, { path: '/', maxAge: 1000000 });
            window.location.href = "/";
        }
    }

    async populateCityList() {
        const response = await fetch('/api/home/getcities');
        const data = await response.json();
        this.setState({ cities: data, loadingCities: false });
    }

    renderCityDropDown() {
        return (<div> Vänligen välj din ort för att fortsätta < br /> <br /><Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>
                Välj ort
            </DropdownToggle><DropdownMenu>
                {this.state.cities.map(city =>
                    <DropdownItem key={city.code} value={city.phone} onClick={() => this.updateActiveCity(city)}>{city.name}</DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
            </div>
        );
    }

    toggle() {
        this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {

        let modalContent = "";
        let closeText = "Stäng";
        let modalSize = "sm";
        
        if(this.props.modalType===1) {
            modalContent =
                <div>Du behöver skriva in ditt telefonnummer för att kunna boka <br /><br />
                <Input onChange={this.props.UpdateValue} type="tel" value={this.props.value} placeholder="Ex.: 0712345678"/></div>
            closeText = "Spara och stäng";
        }    
        else if (this.props.modalType===2) {
            modalContent = <div><iframe id="trailerIFrame" title="Trailer" className="traileriframe" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen src={this.props.videoLink}></iframe></div>
            modalSize = "lg";
        }
        else if(this.props.modalType===3) {
            modalContent =
                <div>Skriv in ditt användarnamn (e-post) och lösenord nedan för att logga in <br /><br />
                    <Input type="text" className="inputLogin" placeholder="Ex.: epost@epost.se"/>
                    <Input type="password" className="inputLogin" placeholder="Ex.: 0712345678"/>
                </div>
            closeText = "Spara och stäng";
        }
        else if (this.props.modalType === "4") {
            console.log("Testar")
            modalContent = this.renderCityDropDown();
            closeText = "Stäng";
        }
        else {
            modalContent = <div>{this.props.modalContents}</div>
        }
        
        return (
            
            <div>
                <Modal size={modalSize} isOpen={this.props.isModalOpen} toggle={this.props.handler} className="someModal">
                    <ModalHeader toggle={this.props.handler}>{this.props.modalHeader}</ModalHeader>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.props.handler}>{closeText}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default GeneralModal;