import React, {Component} from "react";

export class MoviePoster extends Component {
    static displayName = "MoviePoster";

    constructor(props) {
        super(props);
        this.state = {
            cityCode: this.props.cityCode
        };
    }

    render() {

        return (<div>
                    <img
                        key={this.props.movieItem.filmID}
                        value={this.props.movieItem.airTime}
                        alt='' src={this.props.movieItem.poster}
                        className="divMovieImage" />
                </div>);
    }
}
export default MoviePoster;