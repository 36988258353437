import React, { Component } from 'react';
import SuperFilm from './SuperFilm';
import MobileSuperFilm from './MobileSuperFilm';
import QuickList from './QuickList';
import InfoArea from './InfoArea';
import { MovieCarousel } from "./MovieCarousel";
import GeneralModal from "../General/Modal";

export class Home extends Component {
    static displayName = Home.name;
        
    constructor(props) {
        super(props);

        this.launchTrailer = this.launchTrailer.bind(this);
        this.handler = this.handler.bind(this);

        this.state = {
            isModalOpen: false,
            modalContents: "",
            modalType: "",
            modalHeader: "Trailer"
        };
    }

    componentDidMount() {
        document.title = "Eurostar Biografer";
        console.log(this.props)
    }

    launchTrailer(filmTrailerURL) {
        this.setState({ isModalOpen: true, modalHeader: "Trailer", modalContents: "", modalType: 2, videoLink: filmTrailerURL });
    }

    handler=()=> {
        this.setState({
            isModalOpen: false
        })
    }
    
    render() {
        
        return (
            
          <div> 
            <div className="page-header">
                <div className="row">
                    <div className="col-md-9 noPadding divMobileSuperFilm">
                        <MobileSuperFilm handler={this.handler} launchTrailer={this.launchTrailer} cityCode={this.props.cityCode} />
                    </div>
                    <div className="col-md-9 noPadding divSuperFilm">
                        <SuperFilm handler={this.handler} launchTrailer={this.launchTrailer} cityCode={this.props.cityCode} />
                    </div>
                    <div className="col-md-3 noPaddingRight divQuickList">
                        <QuickList cityCode={this.props.cityCode} />
                    </div>     
                </div>
                <InfoArea cityCode={this.props.cityCode} />
                <div className="row">
                    <div className="col-md-12 noPadding divSlider">
                        <MovieCarousel cityCode={this.props.cityCode} />
                    </div>
                </div>
                    {/*<Alert color="info">*/}
                    {/*    Pga av belastnigssattacker stänger vi ned sidan tillfälligt och ber att få hänvisa till biografernas telefoner för bokning av biljetter. I stort finns det biljetter till samtliga filmer.*/}
                    {/*</Alert>*/}
                <GeneralModal specialClass="trailerClass" isModalOpen={this.state.isModalOpen} modalHeader={this.state.modalHeader} modalType={this.state.modalType} modalContents={this.state.modalContents} videoLink={this.state.videoLink} handler={this.handler} />
            </div>
          </div>
        );
    }
}
