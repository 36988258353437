import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import {
    Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText
} from 'reactstrap';
import BokaSalongLayout from '.././Boka/BokaSalongLayout';
import MovieInfo from ".././General/MovieInfo";
import MoviePoster from ".././General/MoviePoster";
import GeneralModal from "../General/Modal";
const cookies = new Cookies();

export class Ondemand extends Component {
    static displayName = Ondemand.name;

    constructor(props) {
        super(props);
        const cookieValue = cookies.get('eurostarcookievalues');
        
        this.populateBookMovieTitle = this.populateBookMovieTitle.bind(this)   
        this.launchTrailer = this.launchTrailer.bind(this);
        this.handler = this.handler.bind(this);
        
        this.state = {
            cityCode: cookieValue.cityCode,
            loadingBookMovieList: true,
            loadingBookMovieItem: true,
            bookMovieList: [],
            bookMovieItem: [],
            seatsBooked: 0,
            phoneNo: cookieValue.userPhone,
            isModalOpen: false,
            modalContents: "",
            modalType: "",
            modalHeader: "Trailer"
        };
    }

    componentDidMount() {
       if (this.props.match.params.showingId) {
            this.populateBookMovieView();
        }
        else {
            this.populateBookMovieList();
        }
    }

    populateBookMovieTitle() {
        return (<div><h2>Boka platser - {this.state.bookMovieItem.bookMovie.title}</h2></div>)
    }
    
    async populateBookMovieView() {
        const response = await fetch('api/home/GetBookMovie?showingId=' + this.props.match.params.showingId + "&userPhone=" + this.state.phoneNo);
        const data = await response.json();
        this.setState({ bookMovieItem: data, loadingBookMovieItem: false, videoLink: "https://www.youtube.com/embed/" + data.bookMovie.trailer + "?&autoplay=1&modestbranding=1&rel=0&iv_load_policy=3" });        
    }

    async populateBookMovieList() {
        
        const response = await fetch('api/home/GetBookMovieList?cityCode=' + this.state.cityCode + "&userPhone=" + this.state.phoneNo);
        const data = await response.json();
        let processedItems = data.map(movie =>
            <div className="row rowBoka" key={ movie.filmID } value={movie.filmID}>
                <div className="col-md-1 divBokaImageCell"><img key={movie.filmID} value={movie.airTime}
                                               alt=''
                                               src={movie.poster}
                                               className="divMovieImage" /></div>
                <div className="col-md-5"><Link to={{ pathname: '/Filmer/'+encodeURIComponent(movie.title)+"/"+movie.filmID} }>{movie.title}<br/></Link><p className="pBookMovieDescription">{movie.description}</p></div>
                <div className="col-md-6">{movie.showings.map(showing => <Link key={showing.showFileName} to={{ pathname: '/Boka/'+showing.showFileName} }><div className="divBokaAirTime">{showing.airTime}</div></Link>)}</div>
            </div>
        );
        this.setState({ bookMovieList: processedItems, loadingBookMovieList: false });

    }
    
    launchTrailer() {
        this.setState({isModalOpen: true, modalHeader: "Trailer", modalContents: "", modalType: 2});
    }

    handler() {
        this.setState({
            isModalOpen: false
        })
    }

    render() {                

            if (this.state.loadingBookMovieList) {
                return (<div className="white">Laddar sidan...</div>)
            }

            return (
                <div>
                    <div className="page-header">
                        <h2>Bio on demand</h2>
                        <p>På denna sida kan du själv sätta ihop din egen on demand bokning hos oss. Välj först den tid du vill se en film, sedan filmen du vill se och antalet platser (minimum styrs av bokningstiden som är kopplat till en salong).</p>
                        <div className="row">
                            <div className="col-md-4">
                                <Card>
                                    <CardHeader>Steg 1 - Välj dag och tid</CardHeader>
                                    <CardBody>
                                        <CardText>< select className="form-control btn-default" id="selDate" style={{ width: 100 + "%" }}></select></CardText>
                                    </CardBody>
                                    <CardFooter>Urvalet påverkar filmvalet</CardFooter>
                                </Card>
                            </div>
                            <div class="col-md-4">
                                <Card>
                                    <CardHeader>Steg 2 - Välj film</CardHeader>
                                    <CardBody>
                                        <CardText>< select className="form-control btn-default" id="selMovie" style={{ width: 100 + "%" }}></select></CardText>
                                    </CardBody>
                                    <CardFooter>Urvalet påverkar minimiantalet platser</CardFooter>
                                </Card>
                            </div>
                            <div class="col-md-4">
                                    <Card>
                                        <CardHeader>Steg 3 - Välj antal platser</CardHeader>
                                        <CardBody>
                                            <CardText>< select className="form-control btn-default" id="selMovie" style={{ width: 100 + "%" }}></select></CardText>
                                        </CardBody>
                                        <CardFooter>Minst X och Max X platser</CardFooter>
                                    </Card>
                            </div>
                        </div>
                        <div className="row"><div class="col-md-12"> &nbsp; </div></div>
                        <div className="row">
                            <div class="col-md-4">
                                <Card>
                                    <CardHeader>Steg 4 - Skriv in ditt telefonnummer</CardHeader>
                                    <CardBody>
                                        <CardText><input type="tel" class="form-control" placeholder="Telefonnummer" id="phone" style={{ width: 100 + "%" }} /></CardText>
                                    </CardBody>
                                    <CardFooter>Tänk på att välja telefonnummer kopplat till ditt medlemskonto om du har ett sådant</CardFooter>
                                </Card>
                            </div>
                            <div class="col-md-8">
                                <Card>
                                    <CardHeader>Steg 5 - Godkänn och bekräfta bokning</CardHeader>
                                    <CardBody>
                                        <CardText>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Godkänn köpvillkor
                                                </label>
                                                <button type="button" id="btnBook" name="btnBook" class="btn btn-success" style={{ float: "right" }}><i class="glyphicon glyphicon-book"></i> Bekräfta och betala</button>
                                            </div>
                                        </CardText>
                                    </CardBody>
                                    <CardFooter>Du kommer att bli dirigerad till vår betalningstjänst i det sista steget. Tänk på att du måste hämta ut biljetterna senast 1 dag före visningen</CardFooter>
                                </Card>                                    
                            </div>
                        </div>
                            
                    </div>
                </div>
            );
    }
}
