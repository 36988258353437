import React, {Component} from "react";
import GeneralModal from "../General/Modal";
import {Button, Input} from "reactstrap";
import Cookies from 'universal-cookie';
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";
const cookies = new Cookies();

export class BokaSalongLayout extends Component {
    //static displayName = "BokaSalongLayout";

    constructor(props) {
        super(props);

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.handler = this.handler.bind(this);
        this.flipChair = this.flipChair.bind(this);
        this.addPhoneNo = this.addPhoneNo.bind(this);
        this.emailReceipt = this.emailReceipt.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.triggerNumberModal = this.triggerNumberModal.bind(this);       
        this.handleUpdateValue = this.handleUpdateValue.bind(this);       
        
        this.state = {
            tmpRow: 0,
            activeRow: 0,
            cityCode: this.props.cityCode,
            isModalOpen: false,
            modalContents: "",
            modalType: "",
            booking : [],
            seatsText: "0 platser bokade",
            showFileName: this.props.showFileName,
            phoneNo: this.props.phoneNo,
            time: {},
            seconds: 240,
            modalHeader: "Plats bokad",
            emailAddress: ""
        };
    }

    componentDidMount() {   
        console.log("Quota:" + this.props.bookMovieItem.bookingQuotaReached);
        //if (this.state.phoneNo===undefined) {
        //    this.triggerNumberModal();
        //}
        
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });        
        this.startTimer();
    }
    
    addPhoneNo(phoneNo) {
        this.setState({ phoneNo: phoneNo });
        const cookieValue = cookies.get('eurostarcookievalues');
        let cookieValues = {
            cityCode: cookieValue.cityCode,
            cinemaPhone: cookieValue.cinemaPhone,
            cinemaName: cookieValue.cinemaName,
            userPhone: phoneNo
        }
        cookies.set('eurostarcookievalues', cookieValues, { path: '/', maxAge: 1000000 });
    }

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds === 0) {
            clearInterval(this.timer);
            window.location.reload(false);
        }
    }
    
    flipChair(e, chairId, row, internalId, isVip = false) {

        if (isVip) {

            if (this.props.bookMovieItem.salongsNr === "3" && this.props.bookMovieItem.komplexNr === "37")
            {
                if (!window.confirm('VIP-salong! Du har möjlighet att köpa till mellanpopcorn och läsk 33cl för 40 SEK. Säg till i kassan! Vill du välja denna plats?'))
                {
                    return false;
                }
            }
            else
            {
                let tmpPriceValue = 60;
                if (this.props.bookMovieItem.komplexNr === "18")
                {
                    tmpPriceValue = 50;
                }

                if (!window.confirm('Du valde en VIP plats! VIP platser är fällbara VIP-fåtöljer och popcorn & läsk 33cl ingår. ' + tmpPriceValue + ' SEK tillkommer och läggs på totalen i kassan på biografen. Vill du välja denna plats?')) {
                    return false;
                }
            }
        }

        if (this.state.phoneNo === undefined || this.state.phoneNo === null || this.state.phoneNo.length === 0) {
            this.triggerNumberModal();
            return false;
        }
        
        const currentTime = new Date();
        if (currentTime > new Date(this.props.bookMovieItem.bookingStopTime)) {
            return window.alert('Bokningstid passerad. Du kan ej boka denna visning längre.');
        }

        console.log("Quota:" + this.props.bookMovieItem.bookingQuotaReached);

        if (this.props.bookMovieItem.bookingQuotaReached) {
            return window.alert("Bokningsandelen för denna föreställning är uppnådd. Återstående biljetter säljes direkt i kassan. På följande bild visas hur bokningssituationen ser ut just nu.");
        }

        this.setState({tmpRow: row});
        if (this.state.activeRow !== 0) {
            if (this.state.activeRow !== row) {
                return alert('Du måste välja en plats på samma rad som den förra platsen.');
            }
        }

        if (e.target.src === "https://www.eurostar.se/images/bluechair.gif") {
            e.target.src = "https://www.eurostar.se/images/greenchair.png";
            this.removeBooking(chairId, internalId);
            this.setState({ activeRow: 0 });
        }

        else if ((e.target.src === "https://www.eurostar.se/images/greenchair.png") || (e.target.src === "https://www.eurostar.se/images/goldchair.png")) {
            e.target.src = "https://www.eurostar.se/images/bluechair.gif";
            this.addBooking(chairId, internalId, row);
        }
        else if (e.target.src === "https://www.eurostar.se/images/redchair.png") {
            window.alert("Denna plats är tyvärr redan bokad.");
        }
        
    }

    addBooking(chairId, internalId, tmpRow) {
        if (this.state.booking.indexOf(chairId) < 0) {
            if (this.checkBooking(chairId))
            {
                this.createTempBooking(chairId, internalId, tmpRow);
            }
        }
    }

    removeBooking(chairId, internalId) {        
        if (this.state.booking.indexOf(chairId) > -1) {
            let newBookinglist = this.state.booking;
            newBookinglist.splice(newBookinglist.indexOf(chairId), 1)
            this.setState({booking: newBookinglist});
            this.updateBooking();
            this.cancelBooking(chairId, internalId);
        }        
    }

    updateBooking() {
        let resultText = "Inga platser bokade";
        if (this.state.booking.length === 0) {
            resultText = "Inga platser bokade";
        }
        else {
            resultText = this.state.booking.length + " platser bokade (Platsnr.: " + this.state.booking + ")";
        }

        this.setState({seatsText: resultText});
    }    

    checkBooking(chairId)
    {
        let min = Math.min.apply(null, this.state.booking);
        let max = Math.max.apply(null, this.state.booking);

        if (this.state.booking.length > 0)
        {
            if ((Number(chairId) ===Number( min) - 1) || (Number(chairId) === Number(max) + 1)) {
                if (this.state.booking.length > 8) {
                    alert("Det är ej tillåtet att boka fler än 9 platser per telefonnummer");
                    document.getElementById("stol_" + chairId).src = "https://www.eurostar.se/images/greenchair.png";
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                alert("Du måste boka platser bredvid varandra");
                document.getElementById("stol_" + chairId).src = "https://www.eurostar.se/images/greenchair.png";
                return false;
            }
        }

        return true;
    }
    
    async emailReceipt() {
        if (this.state.emailAddress===undefined || this.state.emailAddress==="") {
            alert("Vänligen skriv in din e-postadress");
            return false;
        }
        if (this.state.phoneNo===undefined) {
            alert("Vänligen skriv in ditt telefonnummer eller logga in");
            return false;
        }
        const response = await fetch('api/home/epostkvitto?showFileName=' + this.state.showFileName + '&phoneNo=' + this.state.phoneNo + '&email=' + this.state.emailAddress)
            .then(function (response) {                      // first then()
                if (response.ok) {
                    return window.alert("Mail skickat!");
                }

                throw new Error('Something went wrong.');
            })
            .then(function (text) {
                //window.alert("Mail skickat!"); // second then()
                console.log('Request successful', text);
            })
            .catch(function (error) {
                window.alert("Ett fel uppstod vid sändning av mailkvittot! Din bokning finns kvar, men kvittot lyckades inte mailas till dig."); // catch
                console.log('Request failed', error);
            });
    }
    
    async createBooking() {
        if (this.state.phoneNo===undefined) {
            alert("Vänligen skriv in ditt telefonnummer eller logga in");
            return false;
        }
        const response = await fetch('api/home/boka?showFileName=' + this.state.showFileName + '&phoneNo=' + this.state.phoneNo)
        const data = await response.json()

        if (data.success) {
            window.location.reload(false);
        } else {
            alert(data.message)
        }
    }

    async cancelBooking(platsId, internalId) {       
        const response = await fetch('api/home/avbokaplats?showFileName=' + this.state.showFileName + '&platsId=' + internalId + '&phoneNo=' + this.state.phoneNo);
        const data = await response.json();
        if (data.success) {
            this.setState({
                time: this.secondsToTime(240),
                seconds: 240,
            });
        } else {
            alert(data.Message)
        }
    }

    async createTempBooking(platsId, internalId, tmpRow) {        
        const response = await fetch('api/home/prelbokaplats?showFileName=' + this.state.showFileName + '&platsId=' + internalId + '&phoneNo=' + this.state.phoneNo)
        const data = await response.json()
        
        if (data.success) {
            let newBookingList = this.state.booking;
            newBookingList.push(platsId);
            this.setState({booking: newBookingList});
            this.updateBooking();
            this.setState({activeRow: tmpRow});
        } else {            
            document.getElementById("stol_" + platsId).src = "https://www.eurostar.se/images/greenchair.png";
            this.removeBooking(platsId, internalId);
            alert(data.message)
        }        
    }

    handler() {
        this.setState({
            isModalOpen: false
        })
    }

    updateEmail(e) {
        this.setState({
            emailAddress: e.target.value
        })
    }

    triggerNumberModal() {
        console.log("Numbermodal")
        this.setState({isModalOpen: true, modalHeader: "Bokningsnummer", modalContents: "", modalType: 1});
    }

    static returnSeat(stol, that) {

        if (stol.aktiv!=="Y") {
            return <img
                key={"stol_" + stol.internnr}
                id={"stol_" + stol.realno}
                src="https://www.eurostar.se/images/greychair.png"
                className="cinemaseat"
                alt={"Plats nr.: " + stol.realno}
                style={{position: "absolute", top: stol.yk, left: stol.xk, opacity:"0.4"}}/>
        }

        let seatImageSource = "https://www.eurostar.se/images/greenchair.png";
        let seatVIPImageSource = "https://www.eurostar.se/images/goldchair.png";
        if (stol.status=== false) {
            seatImageSource = "https://www.eurostar.se/images/redchair.png";
            return <img
                key={"stol_" + stol.internnr}
                id={"stol_" + stol.realno}
                src={seatImageSource}
                className="cinemaseat"
                alt={"Plats nr.: " + stol.realno + " är redan bokad."}
                title={"Plats nr.: " + stol.realno + " är redan bokad."}
                onClick={()=>{that.setState({isModalOpen: true, modalHeader: "Plats bokad", modalContents: "Plats nr.: " + stol.realno + " är redan bokad.", modalType:0})}}
                style={{position: "absolute", top: stol.yk, left: stol.xk, opacity:"1"}}/>
        }

        if (stol.isVIP) {
            return <img
                key={"stol_" + stol.internnr}
                id={"stol_" + stol.realno}
                src={seatVIPImageSource}
                className="cinemaseat"
                alt={"VIP Plats nr.: " + stol.realno}
                title={"VIP Plats nr.: " + stol.realno}
                onClick={e => { that.flipChair(e, stol.realno, stol.rad, stol.internnr, true) }}
                style={{ position: "absolute", top: stol.yk, left: stol.xk, opacity: "1" }} />
        }
        else {
            return <img
                key={"stol_" + stol.internnr}
                id={"stol_" + stol.realno}
                src={seatImageSource}
                className="cinemaseat"
                alt={"Plats nr.: " + stol.realno}
                title={"Plats nr.: " + stol.realno}
                onClick={e => { that.flipChair(e, stol.realno, stol.rad, stol.internnr) }}
                style={{ position: "absolute", top: stol.yk, left: stol.xk, opacity: "1" }} />
        }
    }

    handleUpdateValue(e) {
        this.addPhoneNo(e.target.value);
    }
    
    render() {

        const currentTime = new Date();

        const currentBaseUrl = window.location.href;

        let isVipImage = "";
        let isSofaImage = "";
        if (this.props.bookMovieItem.isVIP === true)
        {
            isVipImage = (<div><img src="https://www.eurostar.se/images/goldchair.png" className="imgChair" alt="VIP plats" title="VIP plats" /> VIP plats</div>);
        }
        if (this.props.bookMovieItem.isSofa === true)
        {
            isSofaImage = (<div><img src="https://www.eurostar.se/images/goldchair.png" className="imgChair" alt="Soffa" title="Soffa" /> soffa</div>);
        }

        let salongTitle = "Klicka nedan för att välja platser";

        if (currentTime > new Date(this.props.bookMovieItem.bookingStopTime)) {
            salongTitle = "Det går tyvärr inte att boka denna visning längre";
        } else {
            salongTitle = "Klicka nedan för att välja platser";
        }
        
        if (this.props.bookMovieItem.receiptHTML) {
            return <div>
                        <div className="divBokaSalonLayout" dangerouslySetInnerHTML={{__html: this.props.bookMovieItem.receiptHTML}}></div>
                        <div className="row">
                            <div className="col-md-7 divBokaEmail"><Input onChange={this.updateEmail} type="email" className="txtBokaEmail" value={this.state.emailAddress} placeholder="Ex.: epost@epost.com" /></div>
                    <div className="col-md-5 divBokaEmail"><Button onClick={() => { this.emailReceipt() }} className="btn-default float-md-right">Maila bekräftelse</Button></div>
                        </div>
                        <br/>
                        Dela denna visning: <FacebookShareButton quote={"Jag ska se " + this.props.movieName + " på " + this.props.cinemaName} hashtag={"EurostarBiografer"} url={currentBaseUrl}><FacebookIcon size={32} round={false} /></FacebookShareButton> &nbsp;
                        <TwitterShareButton title={"Jag ska se " + this.props.movieName + " på " + this.props.cinemaName} hashtags={["EurostarBiografer"]} url={currentBaseUrl}><TwitterIcon size={32} round={false} /></TwitterShareButton>
                    </div>
        }
                
        return (<div className="divBokaSalonLayout">
                    <strong>{salongTitle}</strong>
                    <h3 className="bokaCinemaScreen">
                        <hr />
                    </h3>
                    <div style={{position:"relative", height: (10 + this.props.bookMovieItem.yMax / 31) + "px", width: ((this.props.bookMovieItem.xMax / 16) - 31) + "px",margin: "auto"}}>
                        {this.props.bookMovieItem.salongsStolar.map(stol =>
                            BokaSalongLayout.returnSeat(stol,this))}
                    </div>
                    <div className="divBokaSalongLayoutDescription">
                        <img src="https://www.eurostar.se/images/redchair.png" className="imgChair" alt="Upptagen" title="Upptagen" /> upptagen
                        <img src="https://www.eurostar.se/images/greenchair.png" className="imgChair" alt="Ledig" title="Ledig" /> ledig
                        <img src="https://www.eurostar.se/images/bluechair.png" className="imgChair" alt="Vald" title="Vald" /> vald
                        <img src="https://www.eurostar.se/images/greychair.png" className="imgChair" style={{opacity:"0.4"}}  alt="Avstängd" title="Avstängd" /> avstängd
                        {isVipImage}
                        {isSofaImage}<br/><br/>
                        {this.state.seatsText} platser valda<br/>
                        Tid kvar: m: {this.state.time.m} s: {this.state.time.s} tills dina ev. preliminära bokningar tas bort.
                    </div>

                    <Button disabled={this.state.booking.length===0} onClick={()=>{this.createBooking()}} color="success" className="btnBokaConfirm">Bekräfta bokning</Button>
                    <Button onClick={()=>{this.triggerNumberModal()}} className="btn-default btnBokaUpdateNumber">Ändra tel.nr från {this.state.phoneNo}</Button> 
                    <div className="clearfix"></div>
                    <GeneralModal isModalOpen={this.state.isModalOpen} modalHeader={this.state.modalHeader} modalType={this.state.modalType} modalContents={this.state.modalContents} UpdateValue={this.handleUpdateValue} handler = {this.handler} />
                </div>);
    }
}
export default BokaSalongLayout;