import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Button, CarouselItem, CarouselCaption, Carousel, CarouselIndicators, CarouselControl } from "reactstrap";
const cookies = new Cookies();

const items = [
    {
        altText: 'Slide 1',
        caption: 'Slide 1',
        key: 1,
        src: 'https://picsum.photos/id/123/1200/600'
    },
    {
        altText: 'Slide 2',
        caption: 'Slide 2',
        key: 2,
        src: 'https://picsum.photos/id/456/1200/600'
    },
    {
        altText: 'Slide 3',
        caption: 'Slide 3',
        key: 3,
        src: 'https://picsum.photos/id/678/1200/600'
    }]

export class OmEurostar extends Component {
    static displayName = OmEurostar.name;
        
    constructor(props) {
        super(props);
        this.state = {};        
    }

    componentDidMount() {
        document.title = "Eurostar Biografer - Om Eurostar";
    }

    render() {

        const slides = items.map((item) => {
            return (
                <CarouselItem
                    key={item.src}
                >
                    <img src={item.src} alt={item.altText} />
                    <CarouselCaption
                        captionText={item.caption}
                        captionHeader={item.caption}
                    />
                </CarouselItem>
            );
        });
        
        const cookieValue = cookies.get('eurostarcookievalues');
        const cityCode = cookieValue === null || cookieValue === undefined ? 11 : cookieValue.cityCode;
        
        return (
            
          <div> 
            <div className="page-header">

                <div className="row">
                    <div className="col-md-12 noPadding">
                            <h1>Om Eurostar</h1>
                            Eurostar AB<br/>
                            Hermelinv&#228;gen 6<br />
                            141 40 Huddinge<br /><br />
                            Tel 08 746 03 90
                    </div>
                    </div>

                    {/*<Carousel*/}
                    {/*    activeIndex={0}*/}
                    {/*    next={function noRefCheck() { }}*/}
                    {/*    previous={function noRefCheck() { }}*/}
                    {/*>*/}
                    {/*    <CarouselIndicators*/}
                    {/*        activeIndex={0}*/}
                    {/*        onClickHandler={function noRefCheck() { }}*/}
                    {/*        items={items}*/}
                    {/*    />*/}
                    {/*    {slides}*/}
                    {/*    <CarouselControl*/}
                    {/*        direction="prev"*/}
                    {/*        directionText="Previous"*/}
                    {/*        onClickHandler={function noRefCheck() { }}*/}
                    {/*    />*/}
                    {/*    <CarouselControl*/}
                    {/*        direction="next"*/}
                    {/*        directionText="Next"*/}
                    {/*        onClickHandler={function noRefCheck() { }}*/}
                    {/*    />*/}
                    {/*</Carousel>*/}
            </div>
          </div>
        );
    }
}
