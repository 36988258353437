import React, { Component } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../api-authorization/LoginMenu';
import './NavMenu.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    state = {
        isOpen: false,
        collapsed: true,
        loadingCities: true
    };

    constructor (props) {
        super(props);
        const cookieValue = cookies.get('eurostarcookievalues');
        let userPhone = "";
        let cityCode = "";
        let currentCity = "";

        if (cookieValue != null && cookieValue != undefined) {
            userPhone = cookieValue.userPhone;
            cityCode = cookieValue.cityCode;
            currentCity = cookieValue.cinemaName;
        } else {
            userPhone = "";
            cityCode = 11;
            currentCity = "Falkenberg";
        }

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            isOpen: false,
            collapsed: true,
            userPhone: userPhone,
            cityCode: cityCode,
            currentCity: currentCity
          };
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    componentDidMount() {
          
    }

    render() {
                
        return (
          <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navBarDark shift" light>
              <Container>
                <NavbarBrand tag={Link} onClick={() => { window.location.href = "/" }} to="/">Eurostar // {this.state.currentCity}</NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
                  <ul className="navbar-nav flex-grow">
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" onClick={() => { window.location.href = "/" }} to="/">Hem</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/Boka">Boka biljett</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/Filmer">Filmer</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/Bioprogram">Bioprogram</NavLink>
                    </NavItem>      
                  </ul>
                </Collapse>
                  <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                      <ul className="navbar-nav flex-grow">
                          <LoginMenu></LoginMenu>
                      </ul>
                  </Collapse>
              </Container>
            </Navbar>
          </header>
        );
    }
}
