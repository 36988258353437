import React, { Component, Fragment } from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown} from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import GeneralModal from "../General/Modal";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        const cookieValue = cookies.get('eurostarcookievalues');
        let userPhone = "";
        let cityCode = "";
        let currentCity = "";

        if (cookieValue != null && cookieValue != undefined) {
            userPhone = cookieValue.userPhone;
            cityCode = cookieValue.cityCode;
            currentCity = cookieValue.cinemaName;
        } else {
            userPhone = "";
            cityCode = 11;
            currentCity = "Falkenberg";
        }

        this.updateActiveCity = this.updateActiveCity.bind(this);
        this.renderCityDropDown = this.renderCityDropDown.bind(this);
        this.updateMemberCity = this.updateMemberCity.bind(this);
        this.state = {
            isAuthenticated: false,
            userName: null,
            cities: [],
            loadingCities: true,
            userPhone: userPhone,
            cityCode: cityCode,
            currentCity: currentCity
        };
    }
    
    async checkMemberCity() {

        const token = await authService.getAccessToken()
        if (token) {
            this.populateMemberCity();
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        this.populateCityList();
        this.checkMemberCity();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    renderCityDropDown(cities) {
        return (
            <DropdownMenu right>
                {cities.map(city =>
                    <DropdownItem key={city.code} value={city.phone} onClick={() => this.updateActiveCity(city)}>{city.name}</DropdownItem>
                )}
            </DropdownMenu>
        );
    }

    async populateMemberCity() {
        const token = await authService.getAccessToken();
        let data = null;
        if (token) {
            const response = await fetch('api/member/getmembercity', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(async function (response) {
                if (response.status === 401) {
                    await authService.signOut();
                    return false;
                } else {
                    data = response.json();
                }
            }).catch(function (error) {
                console.log('Fel uppstod: ' + error)
            });            

            if (data) {
                if (Number(data.city) !== Number(this.state.cityCode)) {
                    this.setState({ cityCode: data.city });
                    let tmpItem;
                    // eslint-disable-next-line array-callback-return
                    this.state.cities.map((cityItem) => {
                        if (Number(cityItem.code) === Number(data.city)) {
                            tmpItem = cityItem;
                        }
                    });

                    this.updateActiveCity(tmpItem);
                }
            }
        }
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    async populateCityList() {
        const response = await fetch('/api/home/getcities');
        const data = await response.json();
        this.setState({ cities: data, loadingCities: false });
    }

    updateActiveCity(cityEntry) {
        if (cityEntry) {
            //console.log(cityEntry);
            let cookieValues = {
                cityCode: cityEntry.code,
                cinemaPhone: cityEntry.phone,
                cinemaName: cityEntry.name,
                userPhone: this.state.userPhone === undefined ? this.state.userPhone : ""
            }
            this.setState({ currentCity: cityEntry.name });
            this.updateMemberCity(cityEntry.code);
            cookies.set('eurostarcookievalues', cookieValues, { path: '/', maxAge: 1000000 });
            window.location.href = "/";
        }
}

    async updateMemberCity(cityValue) {
        const token = await authService.getAccessToken();
        if (token) {
            const response = await fetch('api/member/updateMemberCity?cityCode=' + cityValue, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            await response.json();
        }        
    }

    render() {
        let cityDropDownContents = this.state.loadingCities
            ? <span></span>
            : this.renderCityDropDown(this.state.cities);
        let cityDropDownTitle = this.state.currentCity
            ? this.state.currentCity
            : "Välj Stad";

        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath, cityDropDownTitle, cityDropDownContents);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath, cityDropDownTitle, cityDropDownContents);
        }
    }

    authenticatedView(userName, profilePath, logoutPath, cityDropDownTitle, cityDropDownContents) {
        return (<Fragment>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>{cityDropDownTitle}</DropdownToggle>
                {cityDropDownContents}
            </UncontrolledDropdown>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to="/Medlem">Mina sidor</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={logoutPath}>Logga ut</NavLink>*/}
            {/*</NavItem>*/}
        </Fragment>);
    }

    anonymousView(registerPath, loginPath, cityDropDownTitle, cityDropDownContents) {
        return (<Fragment>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>{cityDropDownTitle}</DropdownToggle>
                {cityDropDownContents}
            </UncontrolledDropdown>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={registerPath}>Bli medlem</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <GeneralModal isModalOpen={this.state.isModalOpen} modalHeader={this.state.modalHeader} modalType={this.state.modalType} modalContents={this.state.modalContents} UpdateValue={this.handleUpdateValue} handler = {this.handler} />*/}
            {/*    <NavLink tag={Link} className="text-dark" to={loginPath}>Logga in</NavLink>*/}
            {/*</NavItem>*/}
        </Fragment>);
    }
}
