import React, { Component } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class Kopvillkor extends Component {
    static displayName = Kopvillkor.name;
        
    constructor(props) {
        super(props);
        this.state = {};        
    }
    
    render() {
        
        const cookieValue = cookies.get('eurostarcookievalues');
        const cityCode = cookieValue.cityCode;
        
        return (
            
          <div> 
            <div className="page-header">

                <div className="row">
                    <div className="col-md-12 noPadding">
                            <h1>V�ra k�pvillkor</h1>
                            V�ra k�pvillkor...
                    </div>
                </div>
            </div>
          </div>
        );
    }
}
