import React, { Component } from 'react';

export class BliMedlem extends Component {
    static displayName = BliMedlem.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (

            <div>
                <div className="page-header">
                    <h2>Eurostar Online</h2>
                    Det är enkelt att bli medlem. Bara registrera ditt telefonnummer tillsammans med ett lösenord. Som medlem får du;
                    <br />
                    <br />
                    <ul>
                        <li>Medlemserbjudanden</li>
                        <li>Översikt över bokningar, aktuella och äldre</li>
                        <li>Möjlighet att enkelt avboka dina aktiva bokningar</li>
                        <li>Samla på dig fantastiska poäng</li>
                    </ul>
                </div>
            </div>
        );
    }
}