import React, { Component } from 'react';
import {Redirect, Link} from "react-router-dom";
import authService from './../api-authorization/AuthorizeService'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col, Input } from 'reactstrap';
import Cookies from 'universal-cookie';
import decode from 'jwt-decode';
import QRCode from 'qrcode.react';

const cookies = new Cookies();

export class Medlem extends Component {
  static displayName = Medlem.name;

  constructor(props) {
    super(props);
    const cookieValue = cookies.get('eurostarcookievalues');   
           
    this.toggle = this.toggle.bind(this);
    this.loadActiveBookingData = this.loadActiveBookingData.bind(this);
    this.loadHistoryBookingData = this.loadHistoryBookingData.bind(this);
    this.loadMemberCityData = this.loadMemberCityData.bind(this);
    this.loadCinemaData = this.loadCinemaData.bind(this);
    this.loadCinemaProgramData = this.loadCinemaProgramData.bind(this);
    this.loadFirstData = this.loadFirstData.bind(this);
    this.loadSendoutData = this.loadSendoutData.bind(this);
    this.updateSendout = this.updateSendout.bind(this);
    this.renderMemberCityData = this.renderMemberCityData.bind(this);
    this.cancelSpecificTicket = this.cancelSpecificTicket.bind(this);
    this.renderActiveBookingData = this.renderActiveBookingData.bind(this);
    this.renderBookingHistoryData = this.renderBookingHistoryData.bind(this);
    this.renderCinemaProgramData = this.renderCinemaProgramData.bind(this);
    this.renderCinemaData = this.renderCinemaData.bind(this);
    this.renderSendoutData = this.renderSendoutData.bind(this);
    this.renderSeats = this.renderSeats.bind(this);
    this.fixDate = this.fixDate.bind(this);
    this.fixTime = this.fixTime.bind(this);
    this.invertSetting = this.invertSetting.bind(this);

    this.state = { 
      activeBookings: [],
      loadingActiveBookings: true,
      historyBookings: [],
      loadingHistoryBookings: true,
      cinemaData: [],
      loadingCinemaData: true,
      cinemaProgramData: [],
      loadingCinemaProgramData: true,
      sendoutData: [],
      loadingSendoutData: true,
      profileData: [],
      loadingProfileData: true,
      activeTab: "0",
      phoneNo: cookieValue.userPhone,
      superToken: "",
      loadingMemberCityData: true,
      memberCity: [],
      mailout1: false,
      mailout2: false,
      mailout3: false,
      mailout4: false,
      mailout5: false,
      mailout6: false,
      mailout7: false,
      push1: false,
      push2: false,
      push3: false,
    };
  }

  componentDidMount() {
      this.loadFirstData();
      this.loadMemberCityData();
  }

  async loadFirstData() {
    let sessionObject = decode(await authService.getAccessToken());
    const d = new Date(0);
    d.setUTCSeconds(sessionObject.exp);
      this.setState({ superToken: await authService.getAccessToken() });
  }
  
  toggle = tab => {
    if(this.state.activeTab !== tab) {
      this.setState({activeTab: tab});
    }
    switch (tab) {
      case "0":
        this.loadMemberCityData();
        break;
      case "1":
        this.loadActiveBookingData();
        break;
      case "2":
        this.loadHistoryBookingData();
        break;
      case "3":
        this.loadCinemaData();
        break;
      case "4":
        this.loadCinemaProgramData();
        break;
      case "5":
        this.loadSendoutData();
        break;
      case "6":
        this.loadProfileData();
        break;
      default:
        this.loadMemberCityData();
        break;
    }        
  }

  async loadActiveBookingData() {
    const token = await authService.getAccessToken();
    const response = await fetch('api/member/getmemberactivebookings', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`,credentials: "same-origin" }
    });
    const data = await response.json();
    this.setState({ activeBookings: data, loadingActiveBookings: false });
  }

  async loadHistoryBookingData() {
    const token = await authService.getAccessToken();
    const response = await fetch('api/member/getmemberhistorybookings', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ historyBookings: data, loadingHistoryBookings: false });
  }

  async loadCinemaData() {
    const token = await authService.getAccessToken();
    const response = await fetch('api/member/getmembercinemadata', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ cinemaData: data, loadingCinemaData: false });
  }

  async loadCinemaProgramData() {
    const token = await authService.getAccessToken();
    const response = await fetch('api/member/getmembercinemaprogramdata', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ cinemaProgramData: data.message, loadingCinemaProgramData: false });
  }

  async loadSendoutData() {
    const token = await authService.getAccessToken();
    const response = await fetch('api/member/getmembersendoutdata', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ sendoutData: data, loadingSendoutData: false });
  }

  async loadMemberCityData() {
    const token = await authService.getAccessToken();
    const response = await fetch('api/member/getmembercity', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ memberCity: data, loadingMemberCityData: false });
  }
  
  async loadProfileData() {
    return <Redirect to='/authentication/profile'  />
  }
  
  async updateSendout(varName, varValue) {
    const token = await authService.getAccessToken();
    const response = await fetch('api/member/updatemembersendoutdata?varName=' + varName + "&varValue=" + varValue, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`,credentials: "same-origin" }
    });
    await response.json();
    this.setState({ loadingSendoutData: true });
    await this.loadSendoutData();
  }

  async cancelSpecificTicket(phoneNo, showFileName) {
    const token = await authService.getAccessToken();
      const response = await fetch('api/member/cancelTicket?phoneNo=' + phoneNo + "&showFileName=" + showFileName, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`,credentials: "same-origin" }
    });
    await response.json();
    this.setState({ loadingActiveBookings: true });
    await this.loadActiveBookingData();
  }
  
  cancelTicket(seatNo, showFileName) {
    this.cancelSpecificTicket(seatNo, showFileName);
  }
  
  fixDate(inDate) {
    const tmpDate = new Date(inDate);
    return tmpDate.getFullYear() + "-" + tmpDate.getMonth() + "-" + tmpDate.getDate() + " kl.: " + tmpDate.getHours() + ":" + tmpDate.getMinutes();
  }

  fixTime(inTime) {
    return inTime.replace("00:","").replace(":00","");
  }  
  
  invertSetting (inSetting) {
    return !inSetting;
  }

    renderSeats(seatData) {
        console.log(seatData);
        return <div>{
            seatData.map(booking => (<div key={"seat" + booking.seat.realno}>Plats: {booking.seat.realno} (Pris: {booking.seat.price} SEK) <br/></div>))}</div>
    }

    renderActiveBookingData(memberData) {
        console.log(memberData);
    return (
        <div>
            {memberData.activeBookings.length === 0 ? "Du har inga aktiva bokningar just nu" : memberData.activeBookings.map(booking =>
              <Card className="rowBokat" key={booking.showing.showFileName}>
                <div className="row">
                    <div className="col-md-4">
                        <strong>{booking.movie.title}</strong><br/>
                            Visas:{this.fixDate(booking.showing.airTime)}<br />
                            Platser bokade;<br/>
                        {this.renderSeats(booking.memberBookingsViewModels)}                           
                    </div>
                    <div className="col-md-3">
                        Biograf: {booking.cinema.name}<br />
                        Salong: {booking.salon.name + " " + booking.salon.redGreen}<br />
                        Ort: {booking.cinema.city}<br /><br />
                    </div>
                    <div className="col-md-3 text-right">
                            <Link to="#" onClick={() => { if (window.confirm('Är du säker på att du vill avboka denna plats?')) { this.cancelTicket(memberData.phoneNo, booking.showing.showFileName) } }}>Avboka</Link><br /><br /><br />
                        <span className="font-italic">Hämta ut biljetterna senast {this.fixTime(booking.showing.pickupTime)} min innan visningen.</span>
                    </div>
                    <div className="col-md-2 text-right">
                            <QRCode value={booking.showing.showFileName + memberData.phoneNo} />
                    </div>
                </div>
              </Card>)}
        </div>
    );
  }

  renderBookingHistoryData(memberData) {
    return (
        <div>
        {memberData.bookings.length === 0 ? "Du har inga inga gamla bokningar just nu" : memberData.bookings.map(booking =>
              <Card className="rowBokat" key={booking.showing.showFileName + booking.seat.realno}>
                <div className="row">
                  <div className="col-md-6"><strong>{booking.movie.title}</strong></div>
                </div>
                <div className="row">
                  <div className="col-md-3">Plats: {booking.seat.realno}</div>
                  <div className="col-md-3">Biograf: {booking.cinema.name}</div>
                  <div className="col-md-6 text-right">Visades: {this.fixDate(booking.showing.airTime)}</div>
                </div>
                <div className="row">
                  <div className="col-md-3">Pris: {booking.seat.price} SEK</div>
                  <div className="col-md-3">Salong: {booking.salon.name + " " + booking.salon.redGreen}</div>
                  <div className="col-md-6 text-right">Ort: {booking.cinema.city}</div>
                </div>
              </Card>)}
        </div>
    );
  }

  renderCinemaData(data) {
    const cinemaMapURL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2240.413813484536!2d13.301232115994605!3d55.83813319287477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653952b9d18e243%3A0xe2d5e4af28431531!2s" + data.address + "%2C+" + data.city + "%2C+Sverige!5e0!3m2!1ssv!2ses!4v1545981616268";
    return (
        <div>
          Din biograf heter {data.name}. Du hittar den på {data.address} i {data.city} och kan ringa oss på denna biograf på numret {data.phoneNo}.
          <br/>
          <br/>
          <iframe
              src={cinemaMapURL}
              width="100%" 
              height="350" 
              frameBorder="0" 
              allowFullScreen
              title="Karta till biograf">            
          </iframe>
        </div>
    );
  }

  renderCinemaProgramData(data) {
    const pdfLink = "/laddaner/" + data + ".pdf";
    return (
        <div>
          <a href={pdfLink}>Ladda ner ditt lokala filmprogram som PDF här</a>
        </div>
    );
  }  
  
  renderSendoutData(data) {
        console.log(data);
    return (
        <div className="row">
            <div className="col-4">
                E-post
                <ul>
                    <li><Input type="checkbox" checked={data.mailout1} onChange={() => { this.updateSendout("mailout1", this.invertSetting(data.mailout1)) }} /> Veckotablå</li>
                    <li><Input type="checkbox" checked={data.mailout2} onChange={() => { this.updateSendout("mailout2", this.invertSetting(data.mailout2)) }} /> Rek. personlig visning</li>
                    <li><Input type="checkbox" checked={data.mailout3} onChange={() => { this.updateSendout("mailout3", this.invertSetting(data.mailout3)) }} /> Premiärer</li>
                    {/*<li><Input type="checkbox" checked={data.mailout5} onChange={() => { this.updateSendout("mailout5", this.invertSetting(data.mailout5)) }} /> Erbjudanden</li>*/}
                    <li><Input type="checkbox" checked={data.mailout6} onChange={() => { this.updateSendout("mailout6", this.invertSetting(data.mailout6)) }} /> Påmin.inför.visn</li>
                </ul>
            </div>
            <div className="col-8">
                Ställ in dina preferenser för filmrekommendationer;
                <br /><br />
                <ul className="text-start">
                    {data.genrePreferences.map((genrePreference) => (
                        <li key={"genrePrefenceChkBox" + genrePreference.id} className="d-inline me-2"><input type="checkbox" id={genrePreference.id} onClick={() => this.updateSendout(genrePreference.id, this.invertSetting(genrePreference.checked))} defaultChecked={genrePreference.checked} /> {genrePreference.name}</li>
                    ))}                    
                </ul>
            </div>
        </div>
    );
    }

    renderMemberCityData(data) {
        //return <div></div>
        return <div><QRCode size="200" value={data.guid} /></div>
    }

  renderProfileData(data) {
    return (
        <div>
          E-post
          <ul>
            <li><Input type="checkbox" checked={data.mailout1} onChange={() => {this.updateSendout("mailout1",this.invertSetting(data.mailout1))}} /> Veckotablå</li>
            <li><Input type="checkbox" checked={data.mailout2} onChange={() => {this.updateSendout("mailout2",this.invertSetting(data.mailout2))}} /> Rek. personlig visning</li>
            <li><Input type="checkbox" checked={data.mailout3} onChange={() => {this.updateSendout("mailout3",this.invertSetting(data.mailout3))}} /> Premiärer</li>
            <li><Input type="checkbox" checked={data.mailout5} onChange={() => {this.updateSendout("mailout5",this.invertSetting(data.mailout5))}} /> Erbjudanden</li>
            <li><Input type="checkbox" checked={data.mailout6} onChange={() => {this.updateSendout("mailout6",this.invertSetting(data.mailout6))}} /> Påmin.inför.visn</li>
          </ul>
        </div>
    );
  }

  render() {

    let divTab0Contents = "Laddar";
    let divTab1Contents = "Laddar";
    let divTab2Contents = "Laddar";
    let divTab3Contents = "Laddar";
    let divTab4Contents = "Laddar";
    let divTab5Contents = "Laddar";
    let divTab6Contents = "Laddar";

    let activeTabs = [];
      switch (this.state.activeTab) {
        case "0":
          activeTabs = ['tabActive','tabInactive','tabInactive','tabInactive','tabInactive','tabInactive','tabInactive'];
          divTab0Contents = this.state.loadingMemberCityData
              ? <p><em>Laddar...</em></p>
              : this.renderMemberCityData(this.state.memberCity);
          break;
        case "1":
          activeTabs = ['tabInactive','tabActive','tabInactive','tabInactive','tabInactive','tabInactive','tabInactive'];
          divTab1Contents = this.state.loadingActiveBookings
              ? <p><em>Laddar...</em></p>
              : this.renderActiveBookingData(this.state.activeBookings,this.state.phoneNo);
          break;
        case "2":
          activeTabs = ['tabInactive','tabInactive','tabActive','tabInactive','tabInactive','tabInactive','tabInactive'];
          divTab2Contents = this.state.loadingHistoryBookings
              ? <p><em>Laddar...</em></p>
              : this.renderBookingHistoryData(this.state.historyBookings);
          break;
        case "3":
          activeTabs = ['tabInactive','tabInactive','tabInactive','tabActive','tabInactive','tabInactive','tabInactive'];
          divTab3Contents = this.state.loadingCinemaData
              ? <p><em>Laddar...</em></p>
              : this.renderCinemaData(this.state.cinemaData);
          break;
        case "4":
          activeTabs = ['tabInactive','tabInactive','tabInactive','tabInactive','tabActive','tabInactive','tabInactive'];
          divTab4Contents = this.state.loadingCinemaProgramData
              ? <p><em>Laddar...</em></p>
              : this.renderCinemaProgramData(this.state.cinemaProgramData);
          break;
        case "5":
          activeTabs = ['tabInactive','tabInactive','tabInactive','tabInactive','tabInactive','tabActive','tabInactive'];
          divTab5Contents = this.state.loadingSendoutData
              ? <p><em>Laddar...</em></p>
              : this.renderSendoutData(this.state.sendoutData);
          break;
        case "6":
          // activeTabs = ['tabInactive','tabInactive','tabInactive','tabInactive','tabInactive','tabInactive','tabActive'];
          // divTab6Contents = this.state.loadingProfileData
          //     ? <p><em>Laddar...</em></p>
          //     : this.renderProfileData(this.state.profileData);
          divTab6Contents = <Redirect to='/authentication/profile'  />
          break;
        default:
          activeTabs = ['tabActive','tabInactive','tabInactive','tabInactive','tabInactive','tabInactive'];
          break;
      }

    return (
        <div>
          <h2>Mina Sidor</h2>
          <br />
          <Nav tabs>
            <NavItem>
              <NavLink className={activeTabs[0]} onClick={() => { this.toggle('0'); }}>
                Start
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTabs[1]} onClick={() => { this.toggle('1'); }}>
                Mina bokningar
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTabs[2]} onClick={() => { this.toggle('2'); }}>
                Min bokningshistorik
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTabs[3]} onClick={() => { this.toggle('3'); }}>
                Min biograf
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTabs[4]} onClick={() => { this.toggle('4'); }}>
                Ladda ner bioprogram
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTabs[5]} onClick={() => { this.toggle('5'); }}>
                Mina utskicksinställningar
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTabs[6]} onClick={() => { this.toggle('6'); }}>
                Min profil
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane className="tabContent" tabId="0">
              <Row>
                <Col sm="9">
                  <h4 id="spanAvatarName">Välkommen!</h4>
                  Härifrån kan du göra tonvis med spännande saker och ting! <br />Bland annat kan du;
                  <br /><br />
                  <ul>
                    <li>Se dina aktiva bokningar</li>
                    <li>Avboka bokningar</li>
                    <li>Se bokningshistorik</li>
                    <li>Se information om din biograf</li>
                    <li>Ladda ner bioprogram</li>
                    {/*<li>Hantera utskicksinställningar</li>*/}
                    <li>Hantera din profil</li>
                  </ul>                  
                </Col>
                <Col sm="3">
                    <h4 id="spanAvatarName">Din QR kod</h4>
                    {divTab0Contents}
                </Col>
              </Row>
            </TabPane>
            <TabPane className="tabContent" tabId="1">
              <Row>
                <Col sm="12">
                  <h4>Vad ska jag se snart?</h4>
                  Nedan finner du en lista över dina aktiva bokningar. <br /><br />
                  <div id="divActiveBookings">
                    <div id="divActiveBookingsList" className="list-group">
                      {divTab1Contents}
                    </div>
                  </div>
                  <br />
                  Om du inte finner din bokning här kan det bero på att du använt ett annat telefonnummer än det som är kopplat till ditt Eurostar Online Konto.
                </Col>
              </Row>
            </TabPane>
            <TabPane className="tabContent" tabId="2">
              <Row>
                <Col sm="12">
                  <h4>Vad har jag sett innan?</h4>
                  Nedan finner du en historik över dina föregående bokningar. <br/><br/>
                  <div id="divBookingHistoryList">
                    {divTab2Contents}
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane className="tabContent" tabId="3">
              <Row>
                <Col sm="12">
                  <h4>Min biograf</h4>
                  {divTab3Contents}
                </Col>
              </Row>
            </TabPane>
            <TabPane className="tabContent" tabId="4">
              <Row>
                <Col sm="12">
                  <h4>Mitt filmprogram</h4>
                  {divTab4Contents}
                </Col>
              </Row>
            </TabPane>
            <TabPane className="tabContent" tabId="5">
              <Row>
                <Col sm="12">
                    <h4>Mina utskicksinställningar</h4>
                    Härifrån kan du ändra utskicksinställningarna för din profil;
                    <br/><br/>
                    <form>
                        {divTab5Contents}        
                    </form>                   
                </Col>
              </Row>
            </TabPane>
            <TabPane className="tabContent" tabId="6">
              <Row>
                <Col sm="12">
                  <h4>Min profil</h4>
                  Härifrån kan du ändra din profil;
                  <br/><br/>
                  {divTab6Contents}
                  <form>
                    <div className="dropdown">
                      <p>Dina valda ort är {this.state.cityCode} - Välj annan ort i sidhuvudet ovan för att ändra denna</p>                     
                    </div>
                    <hr/>
                    <input type="email" className="form-control inputMemberProfileEmail"
                           placeholder="min@epost.se" id="emailAddress"/>
                    {/*<input type="button" className="btn btn-default btnSaveMemberProfileEmail" onClick="profileEmailChange();"*/}
                    {/*       value="Spara e-post" />*/}
                      

                  </form>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
    );
  }  
}
