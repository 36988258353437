import React, { Component } from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";
import Cookies from 'universal-cookie';
import { saveAs } from "file-saver";
const cookies = new Cookies();

export class BioProgram extends Component {
    static displayName = BioProgram.name;
        
    constructor(props) {
        super(props);

        this.populateProgrammeList = this.populateProgrammeList.bind(this);
        this.renderProgrammeList = this.renderProgrammeList.bind(this);
        this.checkUrl = this.checkUrl.bind(this);
        this.downloadFile = this.downloadFile.bind(this);

        const cookieValue = cookies.get('eurostarcookievalues');

        this.state = {
            cityCode: cookieValue === null || cookieValue === undefined ? 11 : cookieValue.cityCode,
            cityName: cookieValue === null || cookieValue === undefined ? "" : cookieValue.cinemaName,
            phoneNo: cookieValue === null || cookieValue === undefined ? "" : cookieValue.userPhone,
            activeCityLink: "",
            comingCityLink: "",
            programmeList: [],
            loadingProgrammeList: true
        };
    }

    componentDidMount() {
        document.title = "Eurostar Biografer - Bioprogram";
        this.populateProgrammeList(this.state.cityName);
    }

    async downloadFile(file,cityValue) {
        saveAs(file, "EurostarBioprogram.pdf")
        const response = await fetch('api/home/LogProgramDownload?citycode=' + cityValue);
        await response.json();
    }

    async populateProgrammeList(activeCity) {
        const response = await fetch('api/home/getProgrammes');
        const data = await response.json();
        await this.setState({ programmeList: data, loadingProgrammeList: false });
        for (let someThing of data) {
            if (activeCity != null) {
                if (someThing.name === activeCity) {
                    let activeCityLink = someThing.link ? this.checkUrl(someThing.link) : "Saknas";
                    let comingCityLink = someThing.comingLink ? this.checkUrl(someThing.comingLink) : "Saknas";

                    this.setState({
                        activeCityLink: activeCityLink,
                        comingCityLink: comingCityLink
                    });
                }
            }
        }            
    }

    checkFile = async url => {
        fetch(url, { method: "HEAD", mode: 'no-cors' }).then(r => {
            return true;
        })
            .catch(e => {
                return false;
            });
    };

    checkUrl(inURL, cityValue) {
        return this.checkFile("https://www.eurostar.se/laddaner/" + inURL + ".pdf") ? <button className="btnBioprogram" onClick={() => this.downloadFile("https://www.eurostar.se/laddaner/" + inURL + ".pdf", cityValue)}>Ladda ner</button> : "Saknas"
    }

    renderProgrammeList(inData) {
        if (inData && inData.length === 0) {
            return (
                <ul>
                    <li>Inga bioprogram</li>
                </ul>
            )
        }
        return (
            <div>
                {inData.map(programmeEntry =>
                    <div key={programmeEntry.name} className="row rowBoka table-striped">
                        <div className="col-4">{programmeEntry.name}</div>
                        <div className="col-4">{programmeEntry.link ? this.checkUrl(programmeEntry.link, programmeEntry.id) : "Saknas"}</div>
                        <div className="col-4">{programmeEntry.comingLink ? this.checkUrl(programmeEntry.comingLink, programmeEntry.id) : "Saknas"}</div>
                    </div>
                )}
            </div>
        );
    }
    
    render() {

        const currentBaseUrl = window.location.href;
        
        let programmeListContents = this.state.loadingProgrammeList
            ? <p><em>Laddar program...</em></p>
            : this.renderProgrammeList(this.state.programmeList);
        
        return (            
         <div> 
            <div className="page-header">
                <h1>Bioprogram</h1>
                H&auml;r kan du ladda ner det senaste bioprogrammet f&ouml;r vald biograf.<br /><br />
                <div className="row">
                    <div className="col-md-8 noPadding">
                            <div className="row divBokaHeader">
                                <div className="col-4">Biograf</div>
                                <div className="col-4">Nuvarande bioprogram</div>
                                <div className="col-4">Kommande bioprogram</div>
                            </div>
                            <div className="tableBoka">{programmeListContents}</div>
                            <br />
                            <FacebookShareButton quote={"Kolla h�r!"} hashtag={"EurostarBiografer"} url={currentBaseUrl}><FacebookIcon size={32} round={false} /></FacebookShareButton> &nbsp;
                            <TwitterShareButton title={"Kolla h�r!"} hashtags={["EurostarBiografer"]} url={currentBaseUrl}><TwitterIcon size={32} round={false} /></TwitterShareButton>
                            <br />
                            <br />
                    </div>
                </div>
            </div>
         </div>
        );
    }
}
