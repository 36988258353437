import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Footer extends Component {
    static displayName = Footer.name;
    state = {
        
    };

    constructor (props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
        <footer className="footer">
            <div className="container">
                <p className="text-muted">
                    Biljettbokning även via telefon (vid öppethållande): <Link to={"tel:" + this.props.contactPhone}>{this.props.contactPhone}</Link>
                        <span className="hidden-xs"> | <Link to="/OmEurostar">Om Eurostar</Link> | <Link to="/HurViAnvanderCookies">Cookies</Link>{/* | <a target="_blank" href={this.props.facebookPage}>Facebook</a>*/}</span>
                    <span className="hidden-xs footerCopyright">Version {this.props.version} | Eurostar AB</span>                    
                </p>
            </div>
        </footer>);
    }
}