import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import MovieInfo from "../General/MovieInfo";
import { Button } from "reactstrap";
import GeneralModal from "../General/Modal";
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";
const cookies = new Cookies();

export class Filmer extends Component {
    static displayName = Filmer.name;   

    constructor(props) {
        super(props);
        const cookieValue = cookies.get('eurostarcookievalues');

        this.populateCurrentCinemaName = this.populateCurrentCinemaName.bind(this);
        this.launchTrailer = this.launchTrailer.bind(this);
        this.handler = this.handler.bind(this);
        
        this.state = {
            cityCode: cookieValue === null || cookieValue === undefined ? 11 : cookieValue.cityCode,
            cityName: cookieValue === null || cookieValue === undefined ? "" : cookieValue.cinemaName,
            phoneNo: cookieValue === null || cookieValue === undefined ? "" : cookieValue.userPhone,
            loadingBookMovieList: true,
            loadingBookMovieItem: true,
            bookMovieList: [],
            bookMovieItem: [],
            seatsBooked: 0,
            isModalOpen: false,
            modalContents: "",
            modalType: "",
            modalHeader: "Trailer",
            videoLink: ""
        };
    }

    componentDidMount() {
        document.title = "Eurostar Biografer - Filmer";
        this.populateMovieList();
        this.populateCurrentCinemaName();
    }

    async populateCurrentCinemaName() {
        const response = await fetch('api/home/GetCinemaName?cityCode=' + this.state.cityCode);
        const data = await response.json();
        this.setState({ cinemaName: data.name, cityName: data.city });
    }

    launchTrailer(videoLink) {
        this.setState({ isModalOpen: true, modalHeader: "Trailer", modalContents: "", modalType: 2, videoLink: "https://www.youtube.com/embed/" + videoLink + "?&autoplay=1&modestbranding=1&rel=0&iv_load_policy=3" });
    }

    handler() {
        this.setState({
            isModalOpen: false
        })
    }
    
    async populateMovieList() {
        
        const response = await fetch('api/home/GetMovieList?cityCode=' + this.state.cityCode + "&userPhone=" + this.state.phoneNo);
        const data = await response.json();
        let processedItems = data.map(movie =>
            <div className="row rowBoka" key={ movie.filmID } value={movie.filmID}>
                <div className="col-md-2 divBokaImageCell"><img
                    key={movie.filmID}
                    value={movie.airTime}
                    alt=''
                    src={movie.poster}
                    className="divMovieImage" />
                    <br />
                </div>
                <div className="col-md-10"><Link to={{ pathname: '/Filmer/' + encodeURIComponent(movie.title) + "/" + movie.filmID }}>{movie.title}<br /></Link>
                    <MovieInfo city={this.state.cityCode} movieItem={movie} />
                    <Button className="btn-default" onClick={() => this.launchTrailer(movie.trailer)} >Se Trailer</Button>
                    <br />
                    <br />
                </div>
            </div>
        );
        this.setState({ bookMovieList: processedItems, loadingBookMovieList: false });

    }
    
    render() {

        const currentBaseUrl = window.location.href;

        if (this.state.loadingBookMovieList) {
            return (<div className="white">Laddar filmer...</div>)
        }

        return (
            <div>
                <div className="page-header">
                    <h2>Aktuella filmer för {this.state.cinemaName} i {this.state.cityName}</h2>
                    <p>Nedan finns en lista över de filmer (sorterade i bokstavsordning) vi visar på {this.state.cinemaName} i {this.state.cityName}. Klicka på valfri film för mer information.</p>

                    <div className="tableBoka">{this.state.bookMovieList}</div>
                    <br />
                    <FacebookShareButton quote={"Kolla här!"} hashtag={"EurostarBiografer"} url={currentBaseUrl}><FacebookIcon size={32} round={false} /></FacebookShareButton> &nbsp;
                    <TwitterShareButton title={"Kolla här!"} hashtags={["EurostarBiografer"]} url={currentBaseUrl}><TwitterIcon size={32} round={false} /></TwitterShareButton>
                    <br />
                    <br />
                </div>
                <GeneralModal specialClass="trailerClass" isModalOpen={this.state.isModalOpen} modalHeader={this.state.modalHeader} modalType={this.state.modalType} modalContents={this.state.modalContents} videoLink={this.state.videoLink} handler={this.handler} />
            </div>
        );

    }
}
