import React, { Component } from 'react';
import MovieInfo from ".././General/MovieInfo";
import MoviePoster from ".././General/MoviePoster";
import {Button, Table} from "reactstrap";
import GeneralModal from "../General/Modal";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";
const cookies = new Cookies();

export class Film extends Component {
    static displayName = Film.name;

    constructor(props) {
        super(props);
        const cookieValue = cookies.get('eurostarcookievalues');

        this.populateMovieTitle = this.populateMovieTitle.bind(this);
        this.populateMovieView = this.populateMovieView.bind(this);
        this.launchTrailer = this.launchTrailer.bind(this);
        this.handler = this.handler.bind(this);

        this.state = {
            cityCode: cookieValue === null || cookieValue === undefined ? 11 : cookieValue.cityCode,
            cityName: cookieValue === null || cookieValue === undefined ? "" : cookieValue.cinemaName,
            phoneNo: cookieValue === null || cookieValue === undefined ? "" : cookieValue.userPhone,
            loadingMovieItem: true,
            movieItem: [],
            phoneNo: cookieValue === null || cookieValue === undefined ? "" : cookieValue.userPhone,
            isModalOpen: false,
            modalContents: "",
            modalType: "",
            modalHeader: "Trailer"
        };
    }

    componentDidMount() {
        document.title = "Eurostar Biografer - Film";
        this.populateMovieView();
    }

    populateMovieTitle() {
        document.title = "Eurostar Biografer - " + this.state.movieItem.title;
        return (<div><h2>{this.state.movieItem.title}</h2></div>)
    }

    async populateMovieView() {
        const response = await fetch('api/home/GetMovie?filmId=' + this.props.match.params.movieId + "&cityCode=" + this.state.cityCode);
        const data = await response.json();
        await this.setState({ movieItem: data, loadingMovieItem: false, videoLink: "https://www.youtube.com/embed/" + data.trailer + "?&autoplay=1&modestbranding=1&rel=0&iv_load_policy=3" });
    }

    launchTrailer() {
        this.setState({isModalOpen: true, modalHeader: "Trailer", modalContents: "", modalType: 2});
    }

    handler() {
        this.setState({
            isModalOpen: false
        })
    }

    render() {

        const currentBaseUrl = window.location.href;

        if (this.state.loadingMovieItem) {
            return (<div className="white">Laddar film...</div>)
        }

        let showListing = this.state.movieItem.showings.map(showing =>
            <tr key={showing.showFileName}>
                <td className="hidden-xs filmShowingCell">{showing.language}</td>
                <td className="hidden-xs filmShowingCell">{showing.version}</td>
                <td className="hidden-xs filmShowingCell">{showing.biograf}</td>
                <td className="filmShowingCell">{showing.airDate}</td>
                <td className="filmShowingCell">{showing.airTime}</td>
                <td className="filmShowingCell">{showing.seatsLeft}/{showing.seats}</td>
                <td className="filmShowingCell">{showing.price}</td>
                <td className="filmShowingCell"><Link to={{ pathname: '/Boka/' + showing.showFileName}}>Boka</Link></td>
            </tr>
        );
        
        return (
            <div>
                <div className="page-header">
                    <div className="row">
                        <div className="col-md-12">
                            {this.populateMovieTitle()}
                        </div>
                        <div className="col-md-3">
                            <MoviePoster movieItem={this.state.movieItem} />
                        </div>
                        <div className="col-md-9">
                            <MovieInfo city={this.state.cityCode} movieItem={this.state.movieItem} />
                            
                            <Button className="btn-default" onClick={()=>this.launchTrailer()} >Se Trailer</Button>
                            <GeneralModal specialClass="trailerClass" isModalOpen={this.state.isModalOpen} modalHeader={this.state.modalHeader} modalType={this.state.modalType} modalContents={this.state.modalContents} videoLink={this.state.videoLink} handler={this.handler} />
                        </div>
                        <div className="col-md-12">
                            <br/><h4>Visningar</h4>
                            <Table dark striped>
                                <thead>
                                <tr>
                                        <th className="filmShowingHeaderCell hidden-xs">Språk</th>
                                        <th className="filmShowingHeaderCell hidden-xs">Version</th>
                                        <th className="filmShowingHeaderCell hidden-xs">Biograf</th>
                                    <th className="filmShowingHeaderCell">Datum</th>
                                    <th className="filmShowingHeaderCell">Tid</th>
                                    <th className="filmShowingHeaderCell">Platser kvar</th>
                                    <th className="filmShowingHeaderCell">Pris</th>
                                    <th className="filmShowingHeaderCell">Boka</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {showListing}
                                </tbody>
                            </Table>

                            <FacebookShareButton quote={"Kolla här"} hashtag={"EurostarBiografer"} url={currentBaseUrl}><FacebookIcon size={32} round={false} /></FacebookShareButton> &nbsp;
                            <TwitterShareButton title={"Kolla här"} hashtags={["EurostarBiografer"]} url={currentBaseUrl}><TwitterIcon size={32} round={false} /></TwitterShareButton>

                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}
