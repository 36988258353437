import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/General/Layout';
import { Home } from './components/Home/Home';
import { Boka } from './components/Boka/Boka';
import { Ondemand } from './components/Boka/Ondemand';
import { Film } from './components/Filmer/Film';
import { Filmer } from './components/Filmer/Filmer';
import { Medlem } from './components/Medlem/Medlem';
import { OmEurostar } from './components/Home/OmEurostar';
import { HurViAnvanderCookies } from './components/Home/HurViAnvanderCookies';
import { Kopvillkor } from './components/Home/Kopvillkor';
import { BioProgram } from "./components/Home/BioProgram";
import { BliMedlem } from "./components/Medlem/BliMedlem";
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import Cookies from 'universal-cookie';
import './custom.css'
import GeneralModal from "./components/General/Modal";
import CookieConsent from "react-cookie-consent";

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            layoutItem: [],
            cityCode: 11,
            version: "2.6.8",
            isModalOpen: false,
            modalContents: "",
            modalType: "",
            modalHeader: "Trailer",
            cities: []
        };
    }

    async retrieveLayoutData(cityCode) {
        const response = await fetch('/api/home/layoutdata?&cityCode=' + cityCode);
        const data = await response.json();
        await this.setState({ layoutItem: data });
    }


    handler = () => {
        this.setState({
            isModalOpen: false
        })
    }

    async componentDidMount() {
        const cookies = new Cookies();
        const cookieValue = cookies.get('eurostarcookievalues');

        if (cookieValue !== undefined) {
            await this.setState({ cityCode: cookieValue.cityCode });
            await this.retrieveLayoutData(this.state.cityCode);
        }
        else {
            if (!window.location.pathname.toLowerCase().includes('boka')) {
                this.setState({ isModalOpen: true });
            } else {
                this.setState({ isModalOpen: false });
                let pathArray = window.location.pathname.split("/")
                const response = await fetch('api/home/GetBookMovie?showingId=' + pathArray[2] + "&userPhone=");
                const data = await response.json();

                let cookieValues = {
                    cityCode: data.komplexNr,
                    cinemaPhone: data.bookTheatre.phoneNo,
                    cinemaName: data.bookTheatre.city,
                    userPhone: ""
                }

                cookies.set('eurostarcookievalues', cookieValues, { path: '/', maxAge: 1000000 });
                if (this.state.cityCode !== data.komplexNr) {
                    window.location.reload();
                }
            }
        }
    }

    render() {
        const cookies = new Cookies();
        return cookies.get('eurostarcookievalues') ?  (
            <Layout cityCode={this.state.cityCode} facebookPage={this.state.layoutItem.facebookPage} contactPhone={this.state.layoutItem.phone} version={this.state.version} cinemaName={this.state.layoutItem.cinemaname}>
                <Route exact path='/' component={() => <Home cityCode={this.state.cityCode} facebookPage={this.state.layoutItem.facebookPage} />} />
                <Route exact path='/Home' component={() => <Home cityCode={this.state.cityCode} facebookPage={this.state.layoutItem.facebookPage} />} />
                <Route exact path='/Boka' component={Boka} />
                <Route exact path='/BliMedlem' component={BliMedlem} />
                <Route exact path='/Boka/:showingId' component={Boka} />
                <Route exact path='/Ondemand/:showingId' component={Ondemand} />
                <Route exact path='/Ondemand' component={Ondemand} />
                <Route exact path='/Filmer/:movieTitle/:movieId' component={Film} />
                <Route exact path='/Filmer' component={Filmer} />
                <Route exact path='/OmEurostar' component={OmEurostar} />
                <Route exact path='/HurViAnvanderCookies' component={HurViAnvanderCookies} />
                <Route exact path='/Kopvillkor' component={Kopvillkor} />
                <Route exact path='/BioProgram' component={BioProgram} />
                <AuthorizeRoute path='/Medlem' component={Medlem} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <CookieConsent
                    disableStyles={true}
                    location="bottom"
                    buttonClasses="btn btn-primary"
                    containerClasses="alert alert-warning col-lg-12 divCookieContainer"
                    contentClasses="text-capitalize"
                    buttonText="Jag f&#246;rst&#229;r"
                >
                    Denna hemsida anv&#228;nder sig av cookies f&#246;r att lagra inskrivet telefonnummer samt vald stad.{" "}
                </CookieConsent>
            </Layout>
        ) :
            (<GeneralModal specialClass="trailerClass" isModalOpen={this.state.isModalOpen} modalHeader="Eurostar Biografer - V&#228;lj ort" modalType="4" handler={this.handler} />);  
  }
}
