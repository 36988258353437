import {
    Card,
    CardBody,
    CardHeader,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    InputGroup,
    InputGroupAddon,
    InputGroupButtonDropdown,
    ListGroup, ListGroupItem
} from "reactstrap";
import React, {Component} from "react";
import {Link} from "react-router-dom";

export class QuickList extends Component {
    static displayName = "QuickList";

    constructor(props) {
        super(props);
        this.state = { 
            cityCode: this.props.cityCode,
            dates: [],
            showings: [],
            quickshowinglist: [],
            loadingQuickShowingList: true,
            loadingQuickDateList: true,
            collapsed: true,
            activeDate: this.formatDate(new Date()),
        };
        
        this.onQuickDateDropDownSelected = this.onQuickDateDropDownSelected.bind(this);
        this.onQuickShowingListSelected = this.onQuickShowingListSelected.bind(this);
        this.renderQuickShowingList = this.renderQuickShowingList.bind(this);
        this.renderQuickDateDropDown = this.renderQuickDateDropDown.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.checkPreVersion = this.checkPreVersion.bind(this);
        this.checkPostVersion = this.checkPostVersion.bind(this);
    }
    
    componentDidMount() {
        this.populateQuickDateList();
        this.populateQuickShowingList();
        console.log("Quicklist" + this.props.cityCode)
    }

    renderQuickShowingList(showings) {
        if (showings && showings.length===0) {
            return (
                <ListGroup className="speedList" id="speedList"><ListGroupItem>Inga återstående visningar</ListGroupItem></ListGroup>
            )
        }
        return (            
            <ListGroup className="speedList" id="speedList">
                {showings.map(showing =>
                    <Link key={showing.showFileName} to={{ pathname: '/Boka/' + showing.showFileName, query: {filmId: showing.showFileName} }}>
                        <ListGroupItem 
                            key={showing.showFileName} value={showing.showFileName} 
                            onClick={this.onQuickShowingListSelected}>{this.checkPreVersion(showing.version) + showing.movieTitle} 
                            <span className="speedListAirTime">{showing.airTime}</span>{this.checkPostVersion(showing.version, showing.versionFull)}
                        </ListGroupItem>
                    </Link>
                )}
            </ListGroup>
        );
    }

    checkPreVersion(version) {
        if (version !== "" && version !== undefined && version !== null) {
            if (version.length <= 2 && version!=="") {
                return version + " ";
            }
        }
        return "";
    }

    checkPostVersion(version, versionfull) {
        if (version !== "" && version !== undefined && version !== null) {
            if (version.length > 2 && version !== "") {
                return <span><br />{versionfull}</span>
            }
        }
        return "";
    }

    formatDate(inDate) {
        let newDate = new Date(inDate)
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        const separator = "-";

        inDate = `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;

        return  inDate;
    }

    renderQuickDateDropDown(dates) {
        let curDate = this.state.activeDate;
        return (
            <React.Fragment>
                <DropdownToggle caret className="quickDateDropDown">
                    {curDate}
                </DropdownToggle>
                <DropdownMenu>
                    {dates.map(date =>
                        <DropdownItem key={this.formatDate(date)} value={this.formatDate(date)} onClick={this.onQuickDateDropDownSelected}>{this.formatDate(date)}</DropdownItem>
                    )}
                </DropdownMenu>
            </React.Fragment>
        );
    }

    onQuickDateDropDownSelected(event) {
        this.setState({
            collapsed: !this.state.collapsed,
            activeDate: event.target.value,
        });
        event.target.value !== "" ? this.populateQuickShowingList(event.target.value) : console.log();
    }

    onQuickShowingListSelected(event) {
        this.setState({
            value: event.target.innerHTML
        });
    }

    async populateQuickDateList() {
        const response = await fetch('api/home/getquickdatelist?cityCode=' + this.props.cityCode);
        const data = await response.json();
        //console.log("Lista för city: " + this.state.cityCode + " : " + data);
        this.setState({ quickDates: data, activeDate: data.length > 0 ? this.formatDate(data[0]) : this.state.activeDate , loadingQuickDateList: false });

        if (data != null && data.length > 0) {
            this.populateQuickShowingList(this.formatDate(data[0]))
        }
    }

    async populateQuickShowingList(inDate) {
        const response = await fetch('api/home/getquickshowinglist?cityCode=' + this.props.cityCode + '&selectedDate=' + inDate);
        const data = await response.json();
        this.setState({ quickShowingList: data, loadingQuickShowingList: false });
    }    

    render() {

        let quickDateDropdownContents = this.state.loadingQuickDateList
            ? <p><em>Laddar datum...</em></p>
            : this.renderQuickDateDropDown(this.state.quickDates);

        let quickShowingListContents = this.state.loadingQuickShowingList
            ? <p><em>Laddar filmer...</em></p>
            : this.renderQuickShowingList(this.state.quickShowingList);
        
        return (
            <Card>
                <CardHeader>Visningar f&#246;r valt datum</CardHeader>
                <CardBody>
    
                    <form className="form-inline">
                        <div className="form-group">
    
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">V&#228;lj datum</InputGroupAddon>
                                <InputGroupButtonDropdown className="quickdatedropdownBtn" addonType="append">
                                    <Dropdown isOpen={!this.state.collapsed} toggle={this.onQuickDateDropDownSelected}>
                                        {quickDateDropdownContents}
                                    </Dropdown>
                                </InputGroupButtonDropdown>
    
                            </InputGroup>
    
                        </div>
                    </form>
                    <div className="quickListBody">
                        {quickShowingListContents}
                    </div>
                </CardBody>
            </Card>
        );
    }

}
export default QuickList;