import React, { Component } from 'react';
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import GeneralModal from "../General/Modal";
import Carousel from 'fade-carousel';

const divStyle = {
    height: "480px",
    width: "100%"
}
const imageStyle = {
    height: '100%',
    width: 'auto',
    justifySelf: 'center'
}

export class SuperFilm extends Component {
    static displayName = "Superfilm";

    constructor(props) {
        super(props);

        this.launchTrailer = this.launchTrailer.bind(this);
        this.handler = this.handler.bind(this);

        this.state = {
            collapsed: true,
            superFilmContents: [],
            loadingSuperFilmContents: true,
            isModalOpen: false,
            modalContents: "",
            modalType: "",
            modalHeader: "Trailer"
        };
    }

    async populateSuperfilmContents() {
        const response = await fetch('api/home/getsuperfilmcontents?cityCode=' + this.props.cityCode);
        const data = await response.json();
        this.setState({ superfilmContents: data, loadingSuperFilmContents: false });
    }

    componentDidMount() {
        this.populateSuperfilmContents();
    }

    launchTrailer(filmTrailerURL) {
        this.props.launchTrailer(filmTrailerURL);
    }

    handler() {
        this.props.handler();
    }
    
    render() {
        
        if (this.state.loadingSuperFilmContents) {
            return <div>Laddar...</div>
        }

        if (!this.state.loadingSuperFilmContents && (this.state.superfilmContents.superFilmID===null)) {
            return <div></div>
        }
        
        return (
            <Carousel divStyle={divStyle} delay={5000} mode={"fade"} >
                {this.state.superfilmContents.map((url, index) => (
                    <div key={index} style={imageStyle}>
                        <img
                            src={'https://www.eurostar.se/Images/Superfilm/m_' + url.superFilmImage}
                            style={{ width: "100%", height: "auto" }}
                        />
                        <div className="text-center">
                            <Link to={url.superFilmURL}>
                                <Button color="secondary" className="jumbotronbtn">L&#228;s mer/Boka biljett</Button>
                            </Link>
                            {url.superFilmTrailer.length > 0 ? <Button className="btn-default" onClick={() => this.launchTrailer(url.superFilmTrailerURL)}>Se Trailer</Button> : ""}
                        </div>
                    </div>
                ))}
            </Carousel>
        );
    }    
}
export default SuperFilm;