import React, {Component} from "react";

export class MovieInfo extends Component {
    static displayName = "MovieInfo";

    render() {        
        let cinemaInfo = "";
        if (this.props.cinemaInfo==="1") {
            
            cinemaInfo =
                <div className="divBookingDetails row">

                    <div className="col-md-4 divShowInfo">
                        <b>Längd</b>: {this.props.movieItem.runTime}<br/>
                        <b>Språk</b>: {this.props.movieItem.language}<br/>
                        <b>Ålder</b>: {this.props.movieItem.age} <br/>
                        {this.props.movieItem.version ? <span><b>Version</b>: {this.props.movieItem.version}<br /></span> : "" } 
                    </div>
                    <div className="col-md-4 divShowInfo">
                        <b>Datum</b>: {this.props.movieItem.showings[0].airDate}<br/>
                        <b>Tid</b>: {this.props.movieItem.showings[0].airTime}<br/>
                        <b>Pris</b>: {this.props.movieItem.showings[0].price}<br/>
                    </div>
                    <div className="col-md-4 divShowInfo">
                        <b>Biograf</b>: {this.props.cinemaName}<br/>
                        <b>Salong</b>: {this.props.salonName}<br/>
                        <b>Stad</b>: {this.props.city}<br/>
                    </div>

                </div>                
        }
        
        return (<div>
                    <p>{this.props.movieItem.description}</p>
                    <p>Regissör: {this.props.movieItem.directors}<br/>
                        Skådespelare: {this.props.movieItem.main_Actors}</p>
                
                    {cinemaInfo}
                </div>);
    }
}
export default MovieInfo;